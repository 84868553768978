
import { mapGetters } from "vuex";

const curatorFeeds = {
  // International
  default: {
    id: "curator-feed-default-feed-layout",
    src: "https://cdn.curator.io/published/32e155b2-b654-4b65-a8d1-79aa50444b0e.js",
  },
  // Malasya
  my: {
    id: "curator-feed-benda-malaysia-layout",
    src: "https://cdn.curator.io/published/04ef5ebe-ac04-4196-825e-2213988cd393.js",
  },
  // Spain
  es: {
    id: "curator-feed-benda-spain-layout",
    src: "https://cdn.curator.io/published/1aec8596-9e73-4bac-b972-78c99cdd4791.js",
  },
  // Italy
  it: {
    id: "curator-feed-new-feed-layout",
    src: "https://cdn.curator.io/published/fa91e2a0-8d47-456a-b5da-8dd06fa90a80.js",
  },
  // Philippines
  ph: {
    id: "curator-feed-benda-philippines-layout",
    src: "https://cdn.curator.io/published/8934b7c2-53d0-4639-a721-9039abd78a5c.js",
  },
};
export default {
  data() {
    return {
      curatorSettings: curatorFeeds[this.$store.state.country]
        ? curatorFeeds[this.$store.state.country]
        : curatorFeeds.default,
    };
  },
  computed: {
    ...mapGetters({
      currentSite: "getCurrentSite",
    }),
  },
  head() {
    const scripts = [];
    if (process.browser && this.currentSite.settings.curator) {
      scripts.push({
        // class: '_iub_cs_activate',
        // 'data-suppressedsrc': curatorFeeds[this.$store.state.country] ? curatorFeeds[this.$store.state.country].src : curatorFeeds.default.src
        // 'data-iub-purposes': 3,
        crossorigin: true,
        defer: true,
        // type: 'text/plain'
        src: curatorFeeds[this.$store.state.country]
          ? curatorFeeds[this.$store.state.country].src
          : curatorFeeds.default.src,
      });
    }

    return {
      script: scripts,
    };
  },
};
